<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-22T10:10:12+02:00
-->

<template>
  <div class="d-flex flex-column mx-2">

    <div class="d-flex align-items-center">
      <b-button class="p-0 transparent_button" @click="goBack()">
        <img src="@/assets/img/png/ico_goback.png" style="width:3em"/>
      </b-button>
      <div class="mainTitle ml-2 w-auto">
        <div id="mtitle">{{ theme.name_en }}</div>
        <div id="stitle">{{ articles.length }} {{ $t('w.articles') }}</div>
        <hr>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div class="modeChose mr-3">
        <b-button class="transparent_button m-0 p-0" @click="view = 'grid'">
          <div :class="{'modeButton': true, 'activeMode': view == 'grid'}">
            <img src="@/assets/img/svg/ico_grid.svg">
          </div>
        </b-button>
        <b-button class="transparent_button m-0 p-0" @click="view = 'list'">
          <div :class="{'modeButton': true, 'activeMode': view == 'list'}">
            <img src="@/assets/img/svg/ico_list.svg">
          </div>
        </b-button>
      </div>
      <div v-if="view == 'grid'"  class="d-flex align-items-center ml-auto">
        <span class="mr-2 text-nowrap">{{ $t('ph.sort_by') }}</span>
        <b-form-select v-model="sorting_by" :options="sortinglist"></b-form-select>
      </div>
    </div>

    <hr v-if="view == 'grid'" class="w-100">

    <div v-if="articles">
      <articlesGrid v-if="view == 'grid'" :articles="sortingArticles" :no_image="no_image" @saveArticle="saveArticle($event.article, $event.index)" @mediaRedirection="mediaRedirection($event.article)"></articlesGrid>
      <articlesList v-if="view == 'list'" :articles="sortingArticles" :no_image="no_image" @saveArticle="saveArticle($event.article, $event.index)" @mediaRedirection="mediaRedirection($event.article)"></articlesList>
    </div>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Articles from '@/services/articles'
const articlesService = new Articles();

import articlesList from '@/components/articles/articlesList'
import articlesGrid from '@/components/articles/articlesGrid'

////////////////// Functions //////////////////

function getList(self){
  articlesService.getListFromTheme(self.theme._id).then(function(result) {
    self.articles = result.body.articles;
    self.sources = result.body.sources;

    let savedArticles = result.body.savedArticles;

    for (let article of self.articles) {

      article.__image_loading_error = false;

      // Get source info
      let source = self.sources.find(a => a.uri == article.metadata.source_uri);
      // console.log("source = " + JSON.stringify(source));
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }

      // Is article saved ?
      article.is_saved = savedArticles.findIndex(a => a.uri == article.uri) > -1;

      // article.source_name = source?.name;
      // article.source_country = source?.location?.country?.name;
    }

    self.sorting_by = "date";

    self.loading = false;
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
  });
}

export default {
  name: 'articles',
  props: ['theme','themes','no_image'],
  data () {
    return {
      loading: true,
      articles: [],
      sources: [],
      sortinglist: [],
      sorting_by: null,
      view: "grid",
      current_article: null
    }
  },
  components: {
    articlesList,
    articlesGrid
  },
  computed: {
    sortingArticles: function() {
      console.log(this.sorting_by);

      switch (this.sorting_by) {
        case 'date':
          return this.articles.sort((a,b) => {return (a.metadata.date <  b.metadata.date)? 1 : -1;});
        case 'media':
          return this.articles.sort((a,b) => {return (a.source_name <  b.source_name)? -1 : 1;});
        case 'language':
          return this.articles.sort((a,b) => {return (a.metadata.language <  b.metadata.language)? -1 : 1;});
        default:
          return 0
      }
    },
  },
  created: function() {
    window.addEventListener("resize", this.onResize);

    this.sortinglist = [
      { value: 'date', text: this.$t('w.date')},
      { value: 'media', text: this.$t('w.media')},
      { value: 'language', text: this.$t('w.language')}
    ]

    this.sorting_by = "date";

    this.view = this.$cookies.get('default_articles_view') || 'grid';

    getList(this);
  },
  methods: {
    onResize() {
      if(window.innerWidth < 1079) this.view = 'grid';
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR');
    },
    goBack() {
      this.$emit('goBack')
    },
    saveArticle(article, index){
      let self = this;
      articlesService.saveArticle(article.uri).then(function(result) {
        article.is_saved = result.body.isSaved;
        // To force the update of the article
        self.articles.splice(index, 1, article)
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
      });
    },
    mediaRedirection(article) {
      let self = this;
      articlesService.mediaRedirection(article.uri, this.theme._id).then(function(result) {
        // Nothing
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
      });
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

.modeButton {
  border-radius: 2px;
  padding: 2px 3px 2px 3px;

  img {
    width: 1.5em;
    height: 1.5em;
    filter: invert(63%) sepia(33%) saturate(470%) hue-rotate(177deg) brightness(75%) contrast(91%);
    opacity: 0.8;
  }
}

.activeMode {
  // background-color: $color-1-light;
  img {
    // filter: brightness(0) invert(1);
    filter: invert(23%) sepia(46%) saturate(373%) hue-rotate(177deg) brightness(93%) contrast(93%);
    opacity: 1;
  }
}

@media screen and (max-width: 1079px) {
  .modeChose {
    display: none;
  }
}


</style>
