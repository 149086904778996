/**
 * @Author: laborde
 * @Date:   2018-07-10T10:47:15+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-09-07T10:48:31+02:00
 */

import Vue from 'vue'

export default function RedirectIfAuthenticated (router) {
  router.beforeEach((to, from, next) => {
    var authRequired = to.matched.some(record => record.meta.requiresAuth);

    var access_token_sbapp = window.$cookies.get('access_token_sbapp');
    if (access_token_sbapp == undefined && to.name !== "login" && authRequired) {
      console.log("Redirect to login");
      return next('/app/login?expired=true&to='+ to.path + '&query='+ JSON.stringify(to.query));
    } else if (access_token_sbapp != undefined && ["login","signup"].includes(to.name) && !authRequired) {
      console.log("Redirect to app");
      return next('/app');
    }

    next();
 })
}
