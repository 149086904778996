/**
 * @Author: laborde
 * @Date:   2018-07-10T10:46:26+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2018-08-09T10:09:06+02:00
 */

import RedirectIfAuthenticated from './redirectIfAuthenticated'

export default function middleware (router) {
   RedirectIfAuthenticated(router)
}
