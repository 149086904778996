/**
 * @Author: laborde
 * @Date:   2022-05-02T09:48:43+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-09-07T10:30:16+02:00
 */

export const fr = {
  p: {

  },
  w: {
    yes: "Oui",
    no: "Non",
    team: 'Equipe',
    plan: 'Offre',
    settings: 'Paramètres',
    language: 'Langue',
    articles: 'Articles',
    themes: 'Thèmes',
    share: 'Partager',
    link: 'Lien',
    title: 'Titre',
    media: 'Média',
    date: 'Date',
    address: "Adresse",
    country: "Pays",
    cancel: "Annuler",
    success: "Succès",
    error: "Erreur"
  },
  ph: {
    select_email_address: "Veuillez sélectionner votre adresse email",
    two_factor_auth: "Authentification à deux facteurs",
    n_days_ago: "%{days} days ago",
    save_for_later: "Enregistrer pour plus tard",
    share_via: "Partager cet article via",
    tax_id: "Identification fiscale",
    I_agree: "J'accepte les ",
    cgu: "Conditions générales d'utilisation",
    expired: "A expiré",
    no_premium_access: "Un abonnement est désormais nécessaire pour accéder à nos services.",
    get_weekly_newsletter: "Recevoir la newsletter hebdomadaire ?",
    add_to_fav: "Ajouter à mes thèmes favoris",
    remove_from_fav: "Supprimer de mes thèmes favoris",
    sort_by: "Trier par",
    default_articles_view: "Affichage par défaut des articles",
    list_mode: 'Mode liste',
    grid_mode: 'Mode quadrillage'
  },
  inputs: {
    first_name: 'Prenom',
    last_name: 'Nom',
    email: 'Email',
    brand: 'Marque',
    company: "Entreprise",
    phone: "Numero",
    username: "Username",
    password: "Mot de passe",
    code: "Code",
    address_1: "Adresse",
    address_2: "Adresse ligne 2",
    postal_code: "Code postal",
    city: "Ville",
    vat_number: "Information TVA",
    company_name: "Nom de l'entreprise"
  },
  languages: {
    fr: "Français",
    en: "Anglais",
  },
  error404: {
    code: '404 ERROR',
    message: 'Page not found'
  },
  errors: {
    missing_input: {
      password: 'Vous devez entrer votre nom d\'utilisateur !',
      username: 'Vous devez entrer votre mot de passe !',
      email: 'Vous devez sélectionner une adresse email !',
      first_name: 'Vous devez entrer votre prénom',
      last_name: 'Vous devez entrer votre nom de famille',
      email: 'Vous devez entrer votre adresse email',
      company: 'Vous devez entrer le nom de votre entreprise',
    },
    unvalid_body_value: {
      email: 'Votre adresse email n\'est pas valide !'
    },
    incorrect_identifiers: 'Votre mot de passe et/ou votre username sont incorrects !',
    code_unvalid: 'Le code saisi est incorrect !',
    deactivated_account: 'Votre compte a été désactivé !',
    unauthorized_IP_address: 'Votre adresse IP actuelle n\'est pas dans la liste des adresses autorisées !',
    internal_error: 'Une erreur inconnue s\'est produite. Si le problème persiste, veuillez contacter notre support.',
    already_used_link: 'Ce lien a déjà été utilisé pour réinitialiser votre mot de passe !',
    expired_link: 'Ce lien a expiré ! Vous pouvez demander un nouveau lien sur support@smartbeautyinsight.com',
    unvalid_link: 'Ce lien n\'est pas valide !',
  },
  warn: {
    request_already_exist: "Une demande de création de compte a déjà été faite via cet email ou à partir de votre adresse IP. Nous vous contacterons sous peu. Merci de votre intérêt !"
  },
  success: {
    signup_request: "Votre demande de création de compte est bien enregistrée.<br>Vous recevrez dans les 24 heures les informations de connexion à votre compte Smart Beauty Insight à l'adresse électronique suivante : %{email}<br><br>N'oubliez pas de vérifier vos spams.",
    password_created: "Votre mot de passe a été créé avec succès",
    password_reset: "Votre mot de passe a été réinitialisé avec succès",
    email_sent: "Email envoyé !",
  },
  btn: {
    create_account: "Créer mon compte",
    signup: "S'inscrire",
    login: "Se connecter",
    logout: "Se déconnecter",
    verify: "Vérifier",
    sent_code: "Envoyer le code",
    resent_code: "Renvoyer le code",
    validate: "Valider",
    upgrade_subscription: "Mettre à jour mon abonnement",
    subscribe: "S'abonner",
  },
  placeholder: {

  },
  login: {
    stay: "Rester connecté pendant une semaine",
    trust: "Faites confiance à cet appareil",
    trust_info: "Effectuez une authentification à deux facteurs seulement une fois par mois.",
    not_have_account: "Vous n'avez pas encore de compte ?",
    get_one: "Demandez-en un ici",
  },
  signup: {
    have_account: "Vous avez déjà un compte ?"
  },
  menu: {
    home: "Derniers articles",
    favorites: "Favoris",
    themes: "Thèmes",
    account: "Compte",
    logout: "Se déconnecter"
  },
  last: {
    t: 'Derniers articles',
  },
  account: {
    t: 'Compte',
    plan: {
      expire_in: 'Expire dans %{days} jours.',
      expired: 'Votre accès premium a expiré.',
      free_expired: 'Votre accès Premium gratuit a expiré.',
      subscription: {
        current: 'Vous disposez actuellement d\'un accès Premium avec un abonnement mensuel.',
        cancel: 'Abonnement annulé (l\'abonnement prendra fin dans %{days} jours).',
        next: 'Prochain paiement dans %{days} jours.',
        popup: {
          t: 'Abonnement confirmé',
          p: 'Merci pour votre abonnement.'
        }
      },
      custom: 'Vous disposez actuellement d\'un accès Premium grâce à un achat personnalisé effectué auprès de notre équipe.',
      team: 'Vous avez actuellement un accès Premium car vous êtes membre de l\'équipe',
      custom_expired: 'Votre accès premium acquis grâce à un achat personnalisé auprès de notre équipe a expiré.',
      team_expired: 'L\'accès Premium de votre équipe a expiré.'
    },
    company_name: "Je suis une entreprise",
    company_name_detail: "Le nom commercial saisi doit être le nom de l'entité juridique de votre entreprise.",
  },
  countries: {
    "afg": "Afghanistan",
    "ala": "Åland",
    "alb": "Albanie",
    "dza": "Algérie",
    "asm": "Samoa américaine",
    "and": "Andorre",
    "ago": "Angola",
    "aia": "Anguilla",
    "ata": "Antarctique",
    "atg": "Antigua et Barbuda",
    "arg": "Argentine",
    "arm": "Arménie",
    "abw": "Aruba",
    "aus": "Australie",
    "aut": "Autriche",
    "aze": "Azerbaïdjan",
    "bhs": "Bahamas",
    "bhr": "Bahrein",
    "bgd": "Bangladesh",
    "brb": "Barbade",
    "blr": "Bielorussie",
    "bel": "Belgique",
    "blz": "Belize",
    "ben": "Bénin",
    "bmu": "Bermudes",
    "btn": "Bhoutan",
    "bol": "Bolivie",
    "bes": "Bonaire, Saint-Eustache et Saba",
    "bih": "Bosnie-Herzégovine",
    "bwa": "Botswana",
    "bvt": "Île Bouvet",
    "bra": "Brésil",
    "iot": "Océan Indien Britannique",
    "brn": "Brunei Darussalam",
    "bgr": "Bulgarie",
    "bfa": "Burkina Faso",
    "bdi": "Burundi",
    "cpv": "Cap-Vert",
    "khm": "Cambodge",
    "cmr": "Cameroun",
    "can": "Canada",
    "cym": "Caïmanes",
    "caf": "République Centrafricaine",
    "tcd": "Tchad",
    "chl": "Chili",
    "chn": "Chine",
    "cxr": "Île Christmas",
    "cck": "Cocos",
    "col": "Colombie",
    "com": "Comores",
    "cog": "République du Congo",
    "cod": "République démocratique du Congo",
    "cok": "Îles Cook",
    "cri": "Costa Rica",
    "civ": "Côte-d'Ivoire",
    "hrv": "Croatie",
    "cub": "Cuba",
    "cuw": "Curaçao",
    "cyp": "Chypre",
    "cze": "République Tchèque",
    "dnk": "Danemark",
    "dji": "Djibouti",
    "dma": "Dominique",
    "dom": "République Dominicaine",
    "ecu": "Équateur",
    "egy": "Égypte",
    "slv": "El Salvador",
    "gnq": "Guinée équatoriale",
    "eri": "Érythrée",
    "est": "Estonie",
    "eth": "Éthiopie",
    "flk": "Îles Malouines",
    "fro": "Îles Féroé",
    "fji": "Fidji",
    "fin": "Finlande",
    "fra": "France",
    "guf": "Guyane française",
    "pyf": "Polynésie française",
    "atf": "Terres australes françaises",
    "gab": "Gabon",
    "gmb": "Gambie",
    "geo": "Géorgie",
    "deu": "Allemagne",
    "gha": "Ghana",
    "gib": "Gibraltar",
    "grc": "Grèce",
    "grl": "Groenland",
    "grd": "Grenada",
    "glp": "Guadeloupe",
    "gum": "Guam",
    "gtm": "Guatemala",
    "ggy": "Guernesey",
    "gin": "Guinée",
    "gnb": "Guinée-Bissau",
    "guy": "Guyana",
    "hti": "Haïti",
    "hmd": "Îles Heard-et-MacDonald",
    "vat": "Saint-Siège",
    "hnd": "Honduras",
    "hkg": "Hong Kong",
    "hun": "Hongrie",
    "isl": "Islande",
    "ind": "Inde",
    "idn": "Indonésie",
    "irn": "Iran",
    "irq": "Irak",
    "irl": "Irlande",
    "imn": "Île de Man",
    "isr": "Israël",
    "ita": "Italie",
    "jam": "Jamaïque",
    "jpn": "Japon",
    "jey": "Jersey",
    "jor": "Jordanie",
    "kaz": "Kazakhstan",
    "ken": "Kenya",
    "kir": "Kiribati",
    "prk": "Corée du Nord",
    "kor": "Corée du Sud",
    "kwt": "Koweit",
    "kgz": "Kirghistan",
    "lao": "Laos",
    "lva": "Lettonie",
    "lbn": "Liban",
    "lso": "Lesotho",
    "lbr": "Libéria",
    "lby": "Libye",
    "lie": "Liechtenstein",
    "ltu": "Lituanie",
    "lux": "Luxembourg",
    "mac": "Macao",
    "mkd": "Macédoine du Nord",
    "mdg": "Madagascar",
    "mwi": "Malawi",
    "mys": "Malaisie",
    "mdv": "Maldives",
    "mli": "Mali",
    "mlt": "Malte",
    "mhl": "Îles Marshall",
    "mtq": "Martinique",
    "mrt": "Mauritanie",
    "mus": "Maurice",
    "myt": "Mayotte",
    "mex": "Mexique",
    "fsm": "Micronésie",
    "mda": "Moldavie",
    "mco": "Monaco",
    "mng": "Mongolie",
    "mne": "Monténégro",
    "msr": "Montserrat",
    "mar": "Maroc",
    "moz": "Mozambique",
    "mmr": "Myanmar",
    "nam": "Namibie",
    "nru": "Nauru",
    "npl": "Népal",
    "nld": "Pays-Bas",
    "ncl": "Nouvelle-Calédonie",
    "nzl": "Nouvelle-Zélande",
    "nic": "Nicaragua",
    "ner": "Niger",
    "nga": "Nigéria",
    "niu": "Niué",
    "nfk": "Île Norfolk",
    "mnp": "Mariannes du Nord",
    "nor": "Norvège",
    "omn": "Oman",
    "pak": "Pakistan",
    "plw": "Palau",
    "pse": "Palestine",
    "pan": "Panama",
    "png": "Papouasie-Nouvelle-Guinée",
    "pry": "Paraguay",
    "per": "Pérou",
    "phl": "Philippines",
    "pcn": "Pitcairn",
    "pol": "Pologne",
    "prt": "Portugal",
    "pri": "Porto Rico",
    "qat": "Qatar",
    "reu": "Réunion",
    "rou": "Roumanie",
    "rus": "Russie",
    "rwa": "Rwanda",
    "blm": "Saint-Barthélemy",
    "shn": "Sainte-Hélène",
    "kna": "Saint-Christophe-et-Niévès",
    "lca": "Sainte-Lucie",
    "maf": "Saint-Martin (partie française)",
    "spm": "Saint Pierre and Miquelon",
    "vct": "Saint-Vincent et les Grenadines",
    "wsm": "Samoa",
    "smr": "Saint-Marin",
    "stp": "São Tomé et Principe",
    "sau": "Arabie Saoudite",
    "sen": "Sénégal",
    "srb": "Serbie",
    "syc": "Seychelles",
    "sle": "Sierra Leone",
    "sgp": "Singapour",
    "sxm": "Saint-Martin (partie néerlandaise)",
    "svk": "Slovaquie",
    "svn": "Slovénie",
    "slb": "Salomon",
    "som": "Somalie",
    "zaf": "Afrique du Sud",
    "sgs": "Géorgie du Sud-et-les Îles Sandwich du Sud",
    "ssd": "Sud-Soudan",
    "esp": "Espagne",
    "lka": "Sri Lanka",
    "sdn": "Soudan",
    "sur": "Suriname",
    "sjm": "Svalbard et Île Jan Mayen",
    "swz": "Eswatini",
    "swe": "Suède",
    "che": "Suisse",
    "syr": "Syrie",
    "twn": "Taïwan",
    "tjk": "Tadjikistan",
    "tza": "Tanzanie",
    "tha": "Thaïlande",
    "tls": "Timor Leste",
    "tgo": "Togo",
    "tkl": "Tokelau",
    "ton": "Tonga",
    "tto": "Trinidad et Tobago",
    "tun": "Tunisie",
    "tur": "Turquie",
    "tkm": "Turkménistan",
    "tca": "Îles Turques-et-Caïques",
    "tuv": "Tuvalu",
    "uga": "Ouganda",
    "ukr": "Ukraine",
    "are": "Émirats Arabes Unis",
    "gbr": "Royaume-Uni",
    "usa": "États-Unis d'Amérique",
    "umi": "Îles mineures éloignées des États-Unis",
    "ury": "Uruguay",
    "uzb": "Ouzbékistan",
    "vut": "Vanuatu",
    "ven": "Venezuela",
    "vnm": "Vietnam",
    "vgb": "Îles vierges britanniques",
    "vir": "Îles vierges américaines",
    "wlf": "Wallis et Futuna",
    "esh": "Sahara occidental",
    "yem": "Yémen",
    "zmb": "Zambie",
    "zwe": "Zimbabwe"
  },
  password: {
    format: {
      p: "Votre mot de passe doit respecter le format suivant :",
      rules: [
        "Entre 10 et 50 charatères",
        "Au moins 1 lettre minuscule et 1 lettre majuscule",
        "Au moins 1 chiffre",
        "Au moins 1 characters speciale",
        "Aucun espace"
      ]
    },
    create: {
      t: "CRÉER VOTRE MOT DE PASSE",
      new_password: "Votre mot de passe",
      confirm_password: "Confirmation de votre mot de passe",
    },
    reset: {
      t: "CRÉER VOTRE NOUVEAU MOT DE PASSE",
      new_password: "Votre nouveau mot de passe",
      confirm_password: "Confirmation de votre nouveau mot de passe",
      missing_password: "Vous devez entrer un mot de passe.",
      same_password: "Vous devez saisir le même mot de passe dans les deux champs.",
    }
  }
}
