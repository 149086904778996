<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-17T09:41:34+02:00
-->

<template>
  <div class="d-flex flex-column">
    <line-loader :loading="loading"></line-loader>

    <articlesComp class="mx-2" :articles="articles"></articlesComp>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Articles from '@/services/articles'
const articlesService = new Articles();

import articlesComp from './articles'

////////////////// Functions //////////////////

function getListArticles(self, callback){
  articlesService.getSavedArticles().then(function(result) {
    self.articles = result.body.articles;

    let sources = result.body.sources;

    for (let article of self.articles) {
      article.__image_loading_error = false;
      let source = sources.find(a => a.uri == article.metadata.source_uri);
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }
      article.is_saved = true;
    }

    self.sorting_by = "date";

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
  });
}

export default {
  name: 'saved',
  data () {
    return {
      loading: true,
      articles: [],
      current_tab: 1
    }
  },
  components: {
    articlesComp
  },
  computed: {

  },
  created: function() {
    let self = this;
    getListArticles(self, function () {
      self.loading = false;
    });
  },
  methods: {

  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

</style>
