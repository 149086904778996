/**
 * @Author: laborde
 * @Date:   2022-03-23T09:33:58+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-17T09:40:20+02:00
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/components/authentication/login'
import signup from '@/components/authentication/signup'
import password from '@/components/authentication/password'

import home from '@/components/home.vue'
import themes from '@/components/themes/themes.vue'
import favorites from '@/components/favorites/favorites.vue'

import account from '@/components/account/account.vue'

Vue.use(VueRouter)

import middleware from './../middleware/middleware'

var _path = '/app';

const routes = [
  {
    path: _path + '/login',
    name: 'login',
    meta: {
      requiresAuth: false,
      title: 'Login - Smart Beauty Insight App'
    },
    component: login
  },
  {
    path: _path + '/signup',
    name: 'signup',
    meta: {
      requiresAuth: false,
      title: 'Signup - Smart Beauty Insight App'
    },
    component: signup
  },
  {
    path: _path + '/password/reset',
    alias: [_path + '/password/create'],
    name: 'password',
    meta: {
      requiresAuth: false,
      title: 'Password - Smart Beauty Insight App'
    },
    component: password
  },
  {
    path: _path + '/home',
    alias: _path,
    name: 'home',
    component: home,
    meta: {
      requiresAuth: true,
      title: 'Home - Smart Beauty Insight App'
    }
  },
  {
    path: _path + '/themes',
    name: 'themes',
    component: themes,
    meta: {
      requiresAuth: true,
      title: 'Themes - Smart Beauty Insight App'
    }
  },
  {
    path: _path + '/favorites',
    name: 'favorites',
    component: favorites,
    meta: {
      requiresAuth: true,
      title: 'Favorites - Smart Beauty Insight App'
    }
  },
  {
    path: _path + '/account',
    name: 'account',
    component: account,
    meta: {
      requiresAuth: true,
      title: 'Account - Smart Beauty Insight App'
    }
  },
  {
    path: '*',
    redirect: _path
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
  next();
})

// App Middleware
middleware(router);

export default router
