/**
 * @Author: laborde
 * @Date:   2019-05-16T13:13:10+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-21T10:28:14+02:00
 */

'use strict'

import User from '@/services/user'
const userService = new User();

export async function getUserData(callback){
  let user_data_sbapp = window.$cookies.get('user_data_sbapp');
  if(user_data_sbapp) {
    let data = (typeof user_data_sbapp == 'string')? JSON.parse(user_data_sbapp) : user_data_sbapp
    return callback(null, data);
  }

  // Get user info
  userService.getUser().then(function(result) {
    let current_user = result.body.current_user;
    window.$cookies.set('user_data_sbapp', JSON.stringify(current_user), 60*10); // 10 minutes
    callback(null, current_user);
  }, function(err) {
    callback(err, null)
  });
}

export async function getUserPlan(callback){
  let user_plan_sbapp = window.$cookies.get('user_plan_sbapp');
  if(user_plan_sbapp) {
    let data = (typeof user_plan_sbapp == 'string')? JSON.parse(user_plan_sbapp) : user_plan_sbapp
    return callback(null, data);
  }

  // Get user info
  userService.getPlan().then(function(result) {
    let current_plan = result.body.current_plan;
    window.$cookies.set('user_plan_sbapp', JSON.stringify(current_plan), 60*10); // 10 minutes
    callback(null, current_plan);
  }, function(err) {
    callback(err, null)
  });
}

export async function getUserTeam(callback){
  let user_team_sbapp = window.$cookies.get('user_team_sbapp');
  if(user_team_sbapp) {
    let data = (typeof user_team_sbapp == 'string')? JSON.parse(user_team_sbapp) : user_team_sbapp
    return callback(null, data);
  }

  // Get user info
  userService.getTeam().then(function(result) {
    let current_team = result.body.current_team;
    window.$cookies.set('user_team_sbapp', JSON.stringify(current_team), 60*10); // 10 minutes
    callback(null, current_team);
  }, function(err) {
    callback(err, null)
  });
}
