<!--
@Author: laborde
@Date:   2018-07-09T09:45:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-07T10:35:08+02:00
-->

<template>
  <div>

    <line-loader :loading="loading"></line-loader>

    <div id="conteneur" class="d-flex flex-column">

      <div class="languageSwitch d-flex">
        <b-button class="flagButton p-0 mr-2 my-auto" @click="switchLanguage('en')" :title="$t('languages.en')">
          <img src="@/assets/img/svg/ico_english_flag.svg" :class="(language == 'en')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
        <b-button class="flagButton p-0 my-auto" @click="switchLanguage('fr')" :title="$t('languages.fr')">
          <img src="@/assets/img/svg/ico_french_flag.svg" :class="(language == 'fr')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
      </div>

      <img class="mt-4 mb-3 mx-auto" src="@/assets/img/png/logo.png" style="height:6em">

      <div class="cont mx-auto mb-3">
        <b-form @submit.prevent="signup()">
          <b-form-input class="myInput mb-2" type="text" v-model="data.first_name" required :placeholder="$t('inputs.first_name')" :disabled="loading"></b-form-input>
          <b-form-input class="myInput mb-2" type="text" v-model="data.last_name" required :placeholder="$t('inputs.last_name')" :disabled="loading"></b-form-input>
          <b-form-input class="myInput mb-2" type="email" v-model="data.email" required :placeholder="$t('inputs.email')" :disabled="loading"></b-form-input>
          <b-form-input class="myInput mb-2" type="text" v-model="data.company" required :placeholder="$t('inputs.company')" :disabled="loading"></b-form-input>

          <div class="d-flex align-items-center mb-2">
            <b-form-checkbox v-model="cguCheckbox" />
            <span>{{ $t('ph.I_agree')}}<button class="largeTitle transparent_button p-0" style="text-decoration: underline;" @click="showCGU = true">{{ $t('ph.cgu')}}</button></span>
          </div>

          <b-button id="signup_button" class="large" type="submit" :disabled="loading || !cguCheckbox"><span>{{$t('btn.create_account')}}</span></b-button>
        </b-form>
      </div>

      <div class="d-flex mx-auto mb-auto">
        <span>{{ $t('signup.have_account') }}</span><button class="transparent_button fontColor1" @click="gotToLogin()">{{ $t('btn.login') }}</button>
      </div>
    </div>

    <b-modal hide-header hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="success">
      <b-button class="crossButton transparent_button" @click="closePopup()">✕</b-button>
      <p class="my-3" v-html="$t('success.signup_request', {email: data.email})" style="text-align:justify;" />
    </b-modal>

    <b-modal hide-header hide-footer size="md" body-bg-variant="warning" v-model="warning">
      <b-button class="crossButton transparent_button" @click="warning = false">✕</b-button>
      <p class="my-3" style="text-align:justify;">{{ $t('warn.request_already_exist') }}</p>
    </b-modal>

    <b-modal hide-header hide-footer size="xl" v-model="showCGU">
      <b-button class="crossButton" @click="showCGU = false">✕</b-button>
      <cguComp></cguComp>
    </b-modal>

  </div>

</template>

<script>

/////////////////// Vue ///////////////////

import cguComp from './cgu'

function errorMessage(self, error) {
  // Default message (in english)
  let message = '' + error.message;

  if(error.error) {
    switch (error.error) {
      case 'internal_error':
        message = self.$t('errors.internal_error')
        break;
    }
  }

  return message;
}

export default {
  data () {
    return {
      loading: false,
      success: false,
      warning: false,
      showCGU: false,
      data: {
        first_name: null,
        last_name: null,
        email: null,
        company: null,
        language: null,
      },
      language: 'en',
      cguCheckbox: false
    }
  },
  components: {
    cguComp
  },
  created: function () {
    this.language = this.$i18n.locale;
  },
  methods: {
    gotToLogin(){
      this.$router.push({name: 'login'});
    },
    switchLanguage(lang){
      // if language already good
      if(lang == this.$i18n.locale) return;
      this.language = lang;
      this.$cookies.set('language', this.language, -1);
      this.$i18n.locale = this.language
    },
    signup () {
      if(!this.data.first_name) this.$bvToast.toast(this.$t('errors.missing_input.first_name'), this.__toaster_opts('error', this.$t('w.error')));
      if(!this.data.last_name) this.$bvToast.toast(this.$t('errors.missing_input.last_name'), this.__toaster_opts('error', this.$t('w.error')));
      if(!this.data.email) this.$bvToast.toast(this.$t('errors.missing_input.email'), this.__toaster_opts('error', this.$t('w.error')));
      if(!this.data.company) this.$bvToast.toast(this.$t('errors.missing_input.company'), this.__toaster_opts('error', this.$t('w.error')));

      let self = this;
      self.loading = true;

      let body = {
        name: this.data.first_name + ' ' + this.data.last_name,
        email: this.data.email,
        company: this.data.company,
        language: this.$i18n.locale,
      }

      this.$http.post(this.$_url + '/auth/signup', body).then((response) => {
        self.loading = false;
        self.success = true;
     }).catch((result) => {
        self.loading = false;
        if(result.body.error && result.body.error == 'too_many_request'){
          self.warning = true;
        } else {
          let message = errorMessage(self, result.body);
          self.$bvToast.toast(errorMessage, self.__toaster_opts('error', self.$t('w.error')));
        }
      })
    },
    closePopup(){
      let keys = Object.keys(this.data);
      for (let key of keys) {
        this.data[key] = null;
      }
      this.success = false;
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#conteneur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  background-color: #f3f3f3;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cont {
  width: 30%;
  background-color: Transparent;
}

#signup_button {
  border:none;
  border-radius: 0;
  background-color:$color-1;
  font-size: 1.2em;
  color: white;
  font-family: 'Raleway-Bold';
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.myInput {
  border-color: #dadada;
}

.myInput::placeholder {
  color: #999999;
}

.large {
  width: 100%;
}

.myLink {
  // font-family: "Roboto-Regular";
  font-size: 12px;
  color: black;
  &:hover, &:active:hover, &:focus {
    color: black;
    font-weight: bolder;
  }
}

#mytitle {
  text-align: center;
  width: 35%;
  font-family: "Raleway-Bold";
  color: white;
  font-size: 36px;
}

#dialcode {
  height: 100%;
  padding: calc(0.375rem + 1px) 0.5em;
  background-color: white;

}

.slash {
  font-size:3em;
  color:#896978;
  margin-top: -5px;
}

@media screen and (max-width: 700px) {
  .cont {
    width: 90%;
  }
}


</style>
