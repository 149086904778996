/**
 * @Author: laborde
 * @Date:   2022-03-23T09:33:58+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-09-07T14:11:31+02:00
 */

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// npm
import Vue from 'vue'
import Resource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'
import VueSocialSharing from 'vue-social-sharing'

// Files
import App from './App.vue'
import router from './router'

// Config
Vue.use(Resource);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(VueSocialSharing);

Vue.prototype.__toaster_opts = function (type, title = '', options) {

  let opts = {
    "title": title,
    "toaster": 'b-toaster-bottom-right',
    "variant": 'success',
    "solid": true,
    "appendToast": true,
    "autoHideDelay": 3000
  };

  if(type == 'success'){
    opts.variant = 'default';
  } else {
    opts.variant = 'secondary';
  }

  if(options && options.duration) {
    opts['autoHideDelay'] = options.duration;
  }

  return opts;
}

/////////////////// components ///////////////////

import loader from '@/components/loader'
Vue.component('line-loader', loader);



/////////////////// Config ///////////////////
Vue.config.productionTip = false

// API URL
Vue.prototype.$_url = location.origin + "/api/v1";
if(process.env.NODE_ENV == "development"){
  Vue.prototype.$_url = "http://" + location.hostname + ":" + process.env.VUE_APP_PORT_API + "/api/v1";
}

// Add translations
import { en } from '@/assets/i18n/en.js'
import { fr } from '@/assets/i18n/fr.js'

var language = navigator.language || navigator.userLanguage;

if(language.startsWith("fr")) language = 'fr';
else language = 'en';

let cookieLanguage = window.$cookies.get('language');
if(cookieLanguage){
  if(cookieLanguage == 'en') language = 'en';
  if(cookieLanguage == 'fr') language = 'fr';
}

Vue.prototype.$_language = language;
const i18n = new VueI18n({
  locale: language,
  messages: {
    'en': en,
    'fr': fr
  }
})

// Vue
var vm = new Vue({
  i18n,
  router,
  render: h => h(App)
});

// Listeners

Vue.http.interceptors.push(function(request, next) {
  // console.log(request.method + " : " + JSON.stringify(request.url) + " / headers : " + JSON.stringify(request.headers));
  var access_token_sbapp = window.$cookies.get('access_token_sbapp');

  if (access_token_sbapp) {
    request.headers.set('Authorization', 'Bearer ' + access_token_sbapp);
  }

  next(function(response) {
    if(response.status == 401 && !request.url.endsWith('/login')){
      window.$cookies.remove('access_token_sbapp');
      window.$cookies.remove('user_data_sbapp');
      window.$cookies.remove('user_plan_sbapp');
      window.$cookies.remove('user_team_sbapp');
      console.log("Redirect to login");
      router.push('/app/login').catch(err => {
        console.error("Can't redirect the user to this path : '/app/login'");
      });
    }

    if(response.status == 403 && response.body.premium_access_require && router.history.current.name != 'account'){
      // The user lost is premium access
      console.log("Redirect to account");
      router.push({ name: 'account', query: { tab: 'plan'} });
    }

  });

});

// Mount the main component
vm.$mount('#app');
