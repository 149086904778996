/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-16T11:29:56+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  getListFromTheme(theme_id) {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?theme_id="+ theme_id);
  }

  getLastArticles() {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?last=true");
  }

  getSavedArticles() {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?saved_only=true");
  }

  saveArticle(uri) {
    return Vue.http.post(Vue.prototype.$_url + "/articles/save/" + uri);
  }

  mediaRedirection(uri, theme_id) {
    return Vue.http.post(Vue.prototype.$_url + "/articles/media/redirection", {uri: uri, theme_id: theme_id});
  }
}
