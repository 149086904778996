/**
 * @Author: laborde
 * @Date:   2022-05-02T09:48:43+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-09-07T10:28:49+02:00
 */

export const en = {
  p: {

  },
  w: {
    yes: "Yes",
    no: "No",
    team: 'Team',
    plan: 'Plan',
    settings: 'Settings',
    language: 'Language',
    articles: 'Articles',
    themes: 'Themes',
    share: 'Share',
    link: 'Link',
    title: 'Title',
    media: 'Media',
    date: 'Date',
    address: "Address",
    country: "Country",
    cancel: "Cancel",
    success: "Success",
    error: "Error"
  },
  ph: {
    select_email_address: "Please select your email address",
    two_factor_auth: "Two-factor authentication",
    n_days_ago: "%{days} days ago",
    save_for_later: "Save for later",
    share_via: "Share this article via",
    tax_id: "Tax identification",
    I_agree: "I accept the ",
    cgu: "Terms of Service",
    expired: "Has expired",
    no_premium_access: "A subscription is now required to access our services.",
    get_weekly_newsletter: "Receive the weekly newsletter ?",
    add_to_fav: "Add to my favorites themes",
    remove_from_fav: "Remove from my favorites themes",
    sort_by: "Sort by",
    default_articles_view: "Default display of articles",
    list_mode: 'List mode',
    grid_mode: 'Grid mode'
  },
  inputs: {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    brand: 'Brand',
    company: "Company",
    phone: "Phone Number",
    username: "Username",
    password: "Password",
    code: "Code",
    address_1: "Address",
    address_2: "Address line 2",
    postal_code: "Postal Code",
    city: "City",
    vat_number: "VAT information",
    company_name: "Name of the company"
  },
  languages: {
    fr: "French",
    en: "English"
  },
  error404: {
    code: '404 ERROR',
    message: 'Page not found'
  },
  errors: {
    missing_input: {
      password: 'You must enter your username !',
      username: 'You must enter your password !',
      email: 'You must select an email address !',
      first_name: 'You must enter your first name',
      last_name: 'You must enter your last name',
      email: 'You must enter your an email address',
      company: 'You must enter your company name',
    },
    unvalid_body_value: {
      email: 'Your email address is unvalid !'
    },
    incorrect_identifiers: 'Your password and/or username are incorrect !',
    code_unvalid: 'The code enter is incorrect !',
    deactivated_account: 'Your account has been deactivated !',
    unauthorized_IP_address: 'Your current IP address is not in the list of allowed addresses !',
    internal_error: 'An unknown error has occurred. If the problem persists, please contact our support.',
    already_used_link: 'This link has already been used to reset your password !',
    expired_link: 'This link has expired! You can request a new link at support@smartbeautyinsight.com',
    unvalid_link: 'This link is not valid !',
  },
  warn: {
    request_already_exist: "A request to create an account has already been made via this email or from your IP address. We will contact you shortly. Thank you for your interest!"
  },
  success: {
    signup_request: "Your request to create an account is well registered.<br>You will receive within 24 hours the connection information to your Smart Beauty Insight account on the following email address: %{email}<br><br>Don't forget to check your spam.",
    password_created: "Your password has been successfully created",
    password_reset: "Your password has been successfully reset",
    email_sent: "Email Sent !",
  },
  btn: {
    create_account: "Create my account",
    signup: "Sign up",
    login: "Log in",
    verify: "Verify",
    sent_code: "Send code",
    resent_code: "Resent code",
    validate: "Validate",
    upgrade_subscription: "Upgrade my subscription",
    subscribe: "Subscribe",
  },
  placeholder: {

  },
  login: {
    stay: "Staying connected for a week",
    trust: "Trust this device",
    trust_info: "Perform two-factor authentication only once a month.",
    not_have_account: "You don't have an account ?",
    get_one: "Request one here",
  },
  signup: {
    have_account: "Already have an account ?"
  },
  menu: {
    home: "Latest articles",
    favorites: "Favorites",
    themes: "Themes",
    account: "Account",
    logout: "Log out"
  },
  last: {
    t: 'Latest articles',
  },
  account: {
    t: 'Account',
    plan: {
      expire_in: 'Expire in %{days} days.',
      expired: 'Your premium access has expired.',
      free_expired: 'Your free premium access has expired.',
      subscription: {
        current: 'Your currently have an Premium access with a monthly subscription.',
        cancel: 'Subscription cancelled (subscription will end in %{days} days).',
        next: 'Next payment in %{days} days.',
        popup: {
          t: 'Subscription confirmed',
          p: 'Thank you for your subscription.'
        }
      },
      custom: 'You currently have Premium access through a personalised purchase made with our team.',
      team: 'You currently have Premium access because you are a member of the team',
      custom_expired: 'Your Premium access acquired through a personalized purchase from our team has expired.',
      team_expired: 'Your team\'s premium access has expired.'
    },
    company_name: "I'm a company",
    company_name_detail: "The business name entered must be your business's legal entity name.",
  },
  countries: {
    "afg": "Afghanistan",
    "ala": "Åland Islands",
    "alb": "Albania",
    "dza": "Algeria",
    "asm": "American Samoa",
    "and": "Andorra",
    "ago": "Angola",
    "aia": "Anguilla",
    "ata": "Antarctica",
    "atg": "Antigua and Barbuda",
    "arg": "Argentina",
    "arm": "Armenia",
    "abw": "Aruba",
    "aus": "Australia",
    "aut": "Austria",
    "aze": "Azerbaijan",
    "bhs": "Bahamas",
    "bhr": "Bahrain",
    "bgd": "Bangladesh",
    "brb": "Barbados",
    "blr": "Belarus",
    "bel": "Belgium",
    "blz": "Belize",
    "ben": "Benin",
    "bmu": "Bermuda",
    "btn": "Bhutan",
    "bol": "Bolivia",
    "bes": "Bonaire, Sint Eustatius and Saba",
    "bih": "Bosnia and Herzegovina",
    "bwa": "Botswana",
    "bvt": "Bouvet Island",
    "bra": "Brazil",
    "iot": "British Indian Ocean Territory",
    "brn": "Brunei Darussalam",
    "bgr": "Bulgaria",
    "bfa": "Burkina Faso",
    "bdi": "Burundi",
    "cpv": "Cape Verde",
    "khm": "Cambodia",
    "cmr": "Cameroon",
    "can": "Canada",
    "cym": "Cayman Islands",
    "caf": "Central African Republic",
    "tcd": "Chad",
    "chl": "Chile",
    "chn": "China",
    "cxr": "Christmas Island",
    "cck": "Cocos (Keeling) Islands",
    "col": "Colombia",
    "com": "Comoros",
    "cog": "Republic of the Congo",
    "cod": "Democratic Republic of the Congo",
    "cok": "Cook Islands",
    "cri": "Costa Rica",
    "civ": "Cote D'Ivoire",
    "hrv": "Croatia",
    "cub": "Cuba",
    "cuw": "Curaçao",
    "cyp": "Cyprus",
    "cze": "Czech Republic",
    "dnk": "Denmark",
    "dji": "Djibouti",
    "dma": "Dominica",
    "dom": "Dominican Republic",
    "ecu": "Ecuador",
    "egy": "Egypt",
    "slv": "El Salvador",
    "gnq": "Equatorial Guinea",
    "eri": "Eritrea",
    "est": "Estonia",
    "eth": "Ethiopia",
    "flk": "Falkland Islands (Malvinas)",
    "fro": "Faroe Islands",
    "fji": "Fiji",
    "fin": "Finland",
    "fra": "France",
    "guf": "French Guiana",
    "pyf": "French Polynesia",
    "atf": "French Southern Territories",
    "gab": "Gabon",
    "gmb": "Gambia",
    "geo": "Georgia",
    "deu": "Germany",
    "gha": "Ghana",
    "gib": "Gibraltar",
    "grc": "Greece",
    "grl": "Greenland",
    "grd": "Grenada",
    "glp": "Guadeloupe",
    "gum": "Guam",
    "gtm": "Guatemala",
    "ggy": "Guernsey",
    "gin": "Guinea",
    "gnb": "Guinea-Bissau",
    "guy": "Guyana",
    "hti": "Haiti",
    "hmd": "Heard Island and McDonald Islands",
    "vat": "Holy See (Vatican City State)",
    "hnd": "Honduras",
    "hkg": "Hong Kong",
    "hun": "Hungary",
    "isl": "Iceland",
    "ind": "India",
    "idn": "Indonesia",
    "irn": "Iran",
    "irq": "Iraq",
    "irl": "Ireland",
    "imn": "Isle of Man",
    "isr": "Israel",
    "ita": "Italy",
    "jam": "Jamaica",
    "jpn": "Japan",
    "jey": "Jersey",
    "jor": "Jordan",
    "kaz": "Kazakhstan",
    "ken": "Kenya",
    "kir": "Kiribati",
    "prk": "North Korea",
    "kor": "South Korea",
    "kwt": "Kuwait",
    "kgz": "Kyrgyzstan",
    "lao": "Lao People's Democratic Republic",
    "lva": "Latvia",
    "lbn": "Lebanon",
    "lso": "Lesotho",
    "lbr": "Liberia",
    "lby": "Libya",
    "lie": "Liechtenstein",
    "ltu": "Lithuania",
    "lux": "Luxembourg",
    "mac": "Macao",
    "mkd": "North Macedonia",
    "mdg": "Madagascar",
    "mwi": "Malawi",
    "mys": "Malaysia",
    "mdv": "Maldives",
    "mli": "Mali",
    "mlt": "Malta",
    "mhl": "Marshall Islands",
    "mtq": "Martinique",
    "mrt": "Mauritania",
    "mus": "Mauritius",
    "myt": "Mayotte",
    "mex": "Mexico",
    "fsm": "Micronesia",
    "mda": "Moldova",
    "mco": "Monaco",
    "mng": "Mongolia",
    "mne": "Montenegro",
    "msr": "Montserrat",
    "mar": "Morocco",
    "moz": "Mozambique",
    "mmr": "Myanmar",
    "nam": "Namibia",
    "nru": "Nauru",
    "npl": "Nepal",
    "nld": "Netherlands",
    "ncl": "New Caledonia",
    "nzl": "New Zealand",
    "nic": "Nicaragua",
    "ner": "Niger",
    "nga": "Nigeria",
    "niu": "Niue",
    "nfk": "Norfolk Island",
    "mnp": "Northern Mariana Islands",
    "nor": "Norway",
    "omn": "Oman",
    "pak": "Pakistan",
    "plw": "Palau",
    "pse": "Palestine",
    "pan": "Panama",
    "png": "Papua New Guinea",
    "pry": "Paraguay",
    "per": "Peru",
    "phl": "Philippines",
    "pcn": "Pitcairn",
    "pol": "Poland",
    "prt": "Portugal",
    "pri": "Puerto Rico",
    "qat": "Qatar",
    "reu": "Reunion",
    "rou": "Romania",
    "rus": "Russia",
    "rwa": "Rwanda",
    "blm": "Saint Barthélemy",
    "shn": "Saint Helena",
    "kna": "Saint Kitts and Nevis",
    "lca": "Saint Lucia",
    "maf": "Saint Martin (French part)",
    "spm": "Saint Pierre and Miquelon",
    "vct": "Saint Vincent and the Grenadines",
    "wsm": "Samoa",
    "smr": "San Marino",
    "stp": "Sao Tome and Principe",
    "sau": "Saudi Arabia",
    "sen": "Senegal",
    "srb": "Serbia",
    "syc": "Seychelles",
    "sle": "Sierra Leone",
    "sgp": "Singapore",
    "sxm": "Sint Maarten (Dutch part)",
    "svk": "Slovakia",
    "svn": "Slovenia",
    "slb": "Solomon Islands",
    "som": "Somalia",
    "zaf": "South Africa",
    "sgs": "South Georgia and the South Sandwich Islands",
    "ssd": "South Sudan",
    "esp": "Spain",
    "lka": "Sri Lanka",
    "sdn": "Sudan",
    "sur": "Suriname",
    "sjm": "Svalbard and Jan Mayen",
    "swz": "Eswatini",
    "swe": "Sweden",
    "che": "Switzerland",
    "syr": "Syrian Arab Republic",
    "twn": "Taiwan",
    "tjk": "Tajikistan",
    "tza": "Tanzania",
    "tha": "Thailand",
    "tls": "Timor-Leste",
    "tgo": "Togo",
    "tkl": "Tokelau",
    "ton": "Tonga",
    "tto": "Trinidad and Tobago",
    "tun": "Tunisia",
    "tur": "Turkey",
    "tkm": "Turkmenistan",
    "tca": "Turks and Caicos Islands",
    "tuv": "Tuvalu",
    "uga": "Uganda",
    "ukr": "Ukraine",
    "are": "United Arab Emirates",
    "gbr": "United Kingdom",
    "usa": "United States of America",
    "umi": "United States Minor Outlying Islands",
    "ury": "Uruguay",
    "uzb": "Uzbekistan",
    "vut": "Vanuatu",
    "ven": "Venezuela",
    "vnm": "Vietnam",
    "vgb": "British Virgin Islands",
    "vir": "United States Virgin Islands",
    "wlf": "Wallis and Futuna",
    "esh": "Western Sahara",
    "yem": "Yemen",
    "zmb": "Zambia",
    "zwe": "Zimbabwe"
  },
  password: {
    format: {
      p: "Your password must be in the following format:",
      rules: [
        "Between 10 and 50 charaters",
        "At least 1 lower case and 1 upper case letter",
        "At least 1 digit",
        "At least 1 special character",
        "No space"
      ]
    },
    create: {
      t: "CREATE YOUR PASSWORD",
      new_password: "Your password",
      confirm_password: "Confirm your password",
    },
    reset: {
      t: "CREATE YOUR NEW PASSWORD",
      new_password: "Your new password",
      confirm_password: "Confirm your new password",
      missing_password: "You must enter an password.",
      same_password: "You must enter the same password in both fields.",
    }
  }
}
