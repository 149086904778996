/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-08-04T10:46:59+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {

  getUser() {
    return Vue.http.get(Vue.prototype.$_url + "/user");
  }

  getPlan() {
    return Vue.http.get(Vue.prototype.$_url + "/user/plan");
  }

  getTeam() {
    return Vue.http.get(Vue.prototype.$_url + "/user/team");
  }

  setWeeklyNews() {
    return Vue.http.post(Vue.prototype.$_url + "/user/newsletters/weekly");
  }
}
