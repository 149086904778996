/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-21T14:21:46+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  createSession(key, address, tax_number, business_name) {
    return Vue.http.post(Vue.prototype.$_url + "/stripe/create-checkout-session", {key: key, address: address, tax_number: tax_number, business_name: business_name});
  }

  createPortalSession() {
    return Vue.http.post(Vue.prototype.$_url + "/stripe/create-portal-session", {});
  }
}
