/**
 * @Author: laborde
 * @Date:   2022-04-04T14:06:21+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-14T10:48:24+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  getList() {
    return Vue.http.get(Vue.prototype.$_url + "/themes/list");
  }

  getFollowedThemes() {
    return Vue.http.get(Vue.prototype.$_url + "/themes/list?followed_only=true");
  }

  follow(id) {
    return Vue.http.post(Vue.prototype.$_url + "/themes/follow/" + id);
  }
}
