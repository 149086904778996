<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-29T11:26:54+02:00
-->

<template>
  <div id="main">

    <!-- <div class="mainTitle d-flex flex-column">
      <div id="mtitle" class="mx-auto mb-0">{{ $t('last.t') }}</div>
      <hr class="my-0 mx-auto">
    </div> -->

    <div style="position:relative">
      <div class="modeChose mr-3">
        <b-button class="transparent_button m-0 p-0" @click="view = 'grid'">
          <div :class="{'modeButton': true, 'activeMode': view == 'grid'}">
            <img src="@/assets/img/svg/ico_grid.svg">
          </div>
        </b-button>
        <b-button class="transparent_button m-0 p-0" @click="view = 'list'">
          <div :class="{'modeButton': true, 'activeMode': view == 'list'}">
            <img src="@/assets/img/svg/ico_list.svg">
          </div>
        </b-button>
      </div>
      <div class="mainTitle d-flex flex-column m-0 p-0">
        <div id="mtitle" class="mx-auto mb-0">{{ $t('last.t') }}</div>
      </div>
    </div>

    <hr v-if="view == 'grid'" class="w-100 my-2">

    <div v-if="articles">
      <articlesGrid v-if="view == 'grid'" :articles="articles" :no_image="null" @saveArticle="saveArticle($event.article, $event.index)" @mediaRedirection="mediaRedirection($event.article)"></articlesGrid>
      <articlesList v-if="view == 'list'" :articles="articles" :no_image="null" @saveArticle="saveArticle($event.article, $event.index)" @mediaRedirection="mediaRedirection($event.article)"></articlesList>
    </div>
  </div>
</template>

<script>

////////////////// Import //////////////////

import Articles from '@/services/articles'
const articlesService = new Articles();

import articlesGrid from '@/components/articles/articlesGrid'
import articlesList from '@/components/articles/articlesList'

////////////////// Functions //////////////////

function getList(self){
  articlesService.getLastArticles().then(function(result) {
    self.articles = result.body.articles;
    self.sources = result.body.sources;

    let savedArticles = result.body.savedArticles;

    for (let article of self.articles) {
      article.__image_loading_error = false;
      // Get source info
      let source = self.sources.find(a => a.uri == article.metadata.source_uri);
      // console.log("source = " + JSON.stringify(source));
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }

      // Is article saved ?
      article.is_saved = savedArticles.findIndex(a => a.uri == article.uri) > -1;

    }

    self.sorting_by = "date";

    self.loading = false;
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
  });
}

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      articles: [],
      sources: [],
      view: "grid",
    }
  },
  components: {
    articlesGrid,
    articlesList
  },
  created: function() {
    window.addEventListener("resize", this.onResize);
    this.view = this.$cookies.get('default_articles_view') || 'grid';
    getList(this);
  },
  methods: {
    onResize() {
      if(window.innerWidth < 1079) this.view = 'grid';
    },
    saveArticle(article, index){
      let self = this;
      articlesService.saveArticle(article.uri).then(function(result) {
        article.is_saved = result.body.isSaved;
        // To force the update of the article
        self.articles.splice(index, 1, article)
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
      });
    },
    mediaRedirection(article) {
      let self = this;
      articlesService.mediaRedirection(article.uri, null).then(function(result) {
        // Nothing
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';

.modeChose {
  position: absolute;
  top: 0.5em;
  left: 0;
}

#mtitle {
  text-align: center;
}

@media screen and (max-width: 1079px) {
  .modeChose {
    display: none;
  }
}

</style>
