<!--
@Author: laborde
@Date:   2020-06-26T12:55:36+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2021-08-16T14:36:53+02:00
-->

<template>
  <div>
    <div v-if="loading">
      <div class="progress">
        <div class="progress-bar indeterminate" :style="myStyle"/>
      </div>
    </div>
    <div style="height:4px" v-if="!loading">
    </div>
  </div>
</template>


<script>

export default {
  name: 'loader',
  props:['loading'],
  data () {
    return {
      myStyle: {}
    }
  },
  created: function () {

  }
}


</script>

<style lang="scss">

@import '@/assets/css/common.scss';

.progress {
  border-radius: 0;
  height: 4px;
}

.progress-bar {
  border-radius: 0;
}

.progress-bar.indeterminate {
  position: relative;
  animation-name: progress-indeterminate !important;
  animation-timing-function: linear !important;
  animation-iteration-count: infinite !important;
  animation-fill-mode: forwards !important;
  animation-delay: 0.5 !important;
  animation-duration: 1s !important;
}

@keyframes progress-indeterminate {
   from { left: -25%; width: 30%; }
   to { left: 100%; width: 30%;}
}

</style>
