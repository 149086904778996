<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-29T11:23:51+02:00
-->

<template>
  <div id="main">
    <line-loader :loading="loading"></line-loader>

    <div id="content" class="mx-auto">

      <div class="mainTitle">
        <div id="mtitle">{{ $t('account.t') }}</div>
        <!-- <div id="stitle"></div> -->
        <hr>
      </div>

      <div v-if="!loading" class="mx-2">

        <div>
          <div class="d-flex mb-2">
            <span class="labelInput mr-2">{{ $t('inputs.username') }} </span>
            <span class="infoInput">{{ current_user.username }}</span>
          </div>

          <div class="d-flex mb-2">
            <span class="labelInput mr-2">{{ $t('inputs.email') }} </span>
            <span class="infoInput">{{ current_user.data.email }}</span>
          </div>

          <div class="d-flex mb-2">
            <span class="labelInput mr-2">{{ $t('inputs.company') }} </span>
            <span class="infoInput">{{ current_user.data.company }}</span>
          </div>

          <div v-if="current_team" class="d-flex mb-2">
            <span class="labelInput mr-2">{{ $t('w.team') }} </span>
            <span class="infoInput">{{ current_team.name }}</span>
          </div>
        </div>

        <div id="tabs" class="mt-3">
          <hr>
          <div class="d-flex pl-2">
            <div @click="current_tab = 2" :class="['subMenuItem','', { 'subMenuItemActive': current_tab == 2 }]">
              <div>{{ $t('w.plan') }}</div>
              <hr>
            </div>
            <div @click="current_tab = 1" :class="['subMenuItem','', { 'subMenuItemActive': current_tab == 1 }]">
              <div>{{ $t('w.settings') }}</div>
              <hr>
            </div>

            <!-- <div v-if="current_team" @click="current_tab = 3" :class="['subMenuItem','', { 'subMenuItemActive': current_tab == 3 }]">
              <div>{{ $t('w.team') }}</div>
              <hr>
            </div> -->
          </div>

          <div class="tabBody" v-if="current_tab == 1">
            <div class="d-flex align-items-center mb-2">
              <span class="largeTitle mr-2">{{ $t('w.language') }} :</span>
              <b-dropdown class="myDropdown mt-1" dropbottom no-caret>
                <template #button-content>
                  <div v-if="(language == 'en')" class="d-flex align-items-center mr-2">
                    <img src="@/assets/img/svg/ico_english_flag.svg" class="mr-1" style="height:1.3em" />
                    <span>{{ $t('languages.en') }}</span>
                  </div>
                  <div v-if="(language == 'fr')" class="d-flex align-items-center ">
                    <img src="@/assets/img/svg/ico_french_flag.svg" class="mr-1" style="height:1.3em" />
                    <span>{{ $t('languages.fr') }}</span>
                  </div>
                </template>
                <b-dropdown-item v-if="(language == 'fr')" @click="switchLanguage('en')">
                  <div class="d-flex align-items-center">
                    <img class="mr-1" src="@/assets/img/svg/ico_english_flag.svg" style="height:1.3em" />
                    <span>{{ $t('languages.en') }}</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="(language == 'en')" @click="switchLanguage('fr')">
                  <div class="d-flex align-items-center">
                    <img class="mr-1" src="@/assets/img/svg/ico_french_flag.svg" style="height:1.3em" />
                    <span>{{ $t('languages.fr') }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="testclass d-flex mb-2">
              <div class="largeTitle mr-2">{{ $t('ph.default_articles_view') }} : </div>
              <b-form-radio class="mr-2" v-model="articles_view" name="some-radios" :value="false" @change="setArticlesView(false)">{{ $t('ph.list_mode') }}</b-form-radio>
              <b-form-radio v-model="articles_view" name="some-radios" :value="true" @change="setArticlesView(true)">{{ $t('ph.grid_mode') }}</b-form-radio>
            </div>

            <div class="d-flex mb-2">
              <div class="largeTitle mr-2">{{ $t('ph.get_weekly_newsletter') }}</div>
              <span class="mr-1">{{ $t('w.no') }}</span>
              <b-form-checkbox v-model="current_user.settings.newsletters.weekly" @change="setWeeklyNews()" switch></b-form-checkbox>
              <span>{{ $t('w.yes') }}</span>
            </div>

          </div>
          <div class="tabBody" v-if="current_tab == 2">
            <div v-if="current_plan" class="d-flex flex-column" style="min-height: 10em;">
              <div class="d-flex flex-column">
                <h5 class="infoInput mr-2">{{ displayPlan(current_plan.plan) }}</h5>
                <div v-if="current_plan.plan == 'free_trial'">
                  <div v-if="!hasExpired">
                    {{ $t('account.plan.expire_in', {days: toStringDate(current_plan.expire_on)}) }}
                  </div>
                  <div v-else>
                    <div class="mb-2">{{ $t('account.plan.free_expired') }}</div>
                  </div>
                </div>
                <div v-if="current_plan.plan == 'premium_starter'">
                  <!-- Stripe -->
                  <div v-if="current_plan.payment_method == 'stripe_month_subscription'">
                    <div  class="mb-2">{{ $t('account.plan.subscription.current') }}</div>
                    <div v-if="current_plan.expire_on">
                      <div v-if="current_plan.stripe.subscription.status == 'cancel'">
                        {{ $t('account.plan.subscription.cancel', {days: toStringDate(current_plan.expire_on)}) }}
                      </div>
                      <div v-else>
                        {{ $t('account.plan.subscription.next', {days: toStringDate(current_plan.expire_on)}) }}
                      </div>
                    </div>
                  </div>
                  <!-- External purchase -->
                  <div v-if="current_plan.payment_method == 'external_purchase'">
                    <div v-if="!hasExpired">
                      <div class="mb-2">{{ $t('account.plan.expire_in', {days: toStringDate(current_plan.expire_on)}) }}</div>
                      <div>{{ $t('account.plan.custom') }}</div>
                    </div>
                    <div v-else>
                      <div>{{ $t('account.plan.custom_expired') }}</div>
                    </div>
                  </div>
                  <!-- Team purchase -->
                  <div v-if="current_plan.payment_method == 'team_purchase'">
                    <div v-if="!hasExpired">
                      <div class="mb-2">{{ $t('account.plan.expire_in', {days: toStringDate(current_plan.expire_on)}) }}</div>
                      <div>{{ $t('account.plan.team') }} "<span class="infoInput">{{ current_team.name }}</span>".</div>
                    </div>
                    <div v-else>
                      <div>{{ $t('account.plan.team_expired') }}</div>
                    </div>
                  </div>

                </div>

              </div>
              <b-button v-if="current_plan.payment_method == 'stripe_month_subscription' && current_plan.stripe.subscription.status == 'active'" class="largeColoredButton mt-auto mr-auto" @click="goToStripe2()">{{ $t('btn.upgrade_subscription') }}</b-button>
              <b-button v-if="canSubscibe" class="largeColoredButton mt-auto mr-auto" @click="subscribe()">{{ $t('btn.subscribe') }}</b-button>
            </div>
          </div>
        </div>

      </div>


    </div>

    <b-modal hide-header hide-footer size="md" v-model="showSubscription">
      <b-button class="crossButton" @click="showSubscription = false">✕</b-button>

      <subscriptionComp v-if="current_user" :current_address="current_user.data.address.billing" @subscribe="goToStripe($event.address, $event.tax_number, $event.business_name)"></subscriptionComp>

    </b-modal>

    <b-modal hide-header hide-footer size="md" v-model="showStripeResult">
      <b-button class="crossButton" @click="showStripeResult = false">✕</b-button>

      <div v-if="stripe.status">
        <h4>{{ $t('account.plan.subscription.popup.t') }}</h4>
        <p>{{ $t('account.plan.subscription.popup.p') }}</p>
      </div>

    </b-modal>

    <b-modal hide-header hide-footer size="md" v-model="showRedirect">
      <b-button class="crossButton" @click="showRedirect = false">✕</b-button>

      <p>{{ $t('ph.no_premium_access') }}</p>

    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import User from '@/services/user'
const userService = new User();

import Checkout from '@/services/checkout'
const checkoutService = new Checkout();

import { getUserData, getUserPlan, getUserTeam } from '@/libs/user';

import subscriptionComp from './subscription'

////////////////// Functions //////////////////

function getPlan(self){
  getUserData(function (err, current_user) {
    if(err) return self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
    self.current_user = current_user;
    getUserPlan(function (err, current_plan) {
      if(err) return self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
      self.current_plan = current_plan;

      // self.current_plan.payment_method = 'external_purchase';
      // self.current_plan.plan = 'free_trial';
      getUserTeam(function (err, current_team) {
        if(err) return self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
        self.current_team = current_team;
        self.loading = false;
      });
    });
  });
}

export default {
  name: 'account',
  data () {
    return {
      loading: true,
      language: null,
      showSubscription: false,
      showStripeResult: false,
      showRedirect: false,
      current_tab: 2,
      current_user: null,
      current_plan: null,
      current_team: null,
      stripe: {
        status: null,
        session_id: null
      },
      articles_view: true
    }
  },
  components: {
    subscriptionComp
  },
  computed: {
    hasExpired: function () {
      if(!this.current_plan?.expire_on || !this.current_plan.expire_on) return true;
      return (new Date(this.current_plan.expire_on)).getTime() < Date.now();
    },
    canSubscibe: function() {
      return this.hasExpired || this.current_plan?.plan == 'free_trial';
    },
    fieldsTable: function () {
      let list = [
        { key: 'username', label: "Username", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'email', label: "Email", sortable: true, thClass: 'listTableSourceNameClass'},
        { key: 'is_admin', label: "Is admin ?", sortable: true},
        { key: 'date', label: "Date", sortable: true},
      ];

      let isAdmin = this.current_team.users.find(a => a.id == this.current_user._id && a.is_admin) != null;
      if(isAdmin) list.push({ key: 'action', label: ""});

      return list;

    }
  },
  created: function() {
    this.language = this.$i18n.locale || 'en';

    this.languages = [
      { value: 'fr', text: this.$t('languages.fr') },
      { value: 'en', text: this.$t('languages.en') }
    ];

    // Check stripe response
    let stripeStatus = this.$route.query['stripe-status'];
    if(stripeStatus != null){
      // Stripe subscription successful
      if(stripeStatus == 'success'){
        this.stripe.status = true;
        this.stripe.session_id = this.$route.query.session_id;
        // Wait 3 seconds : webhook traitement
        // TODO
        // Remove plan cookie (force reload)
        this.$cookies.remove('user_plan_sbapp');
        // Display result
        this.showStripeResult = true;
      } else {
        this.stripe.status = false;
      }
      // this.showStripeResult = true;

      // Remove query after get data
      let path = this.$route.path;
      window.history.replaceState({}, document.title, '' + this.$route.path);

    }

    if(this.$route.query['redirect'] == 'noPremiumAccess'){
      this.showRedirect = true;
      // Remove query after get data
      let path = this.$route.path;
      window.history.replaceState({}, document.title, '' + this.$route.path);
    }

    // Redirect to the right tab
    let tab = this.$route.query['tab'];
    if(tab != null){
      if(tab == 'plan') this.current_tab = 1;
    }

    let default_articles_view = this.$cookies.get('default_articles_view');
    this.articles_view = (default_articles_view)? default_articles_view == 'grid' : true;

    getPlan(this);
  },
  methods: {
    switchLanguage(lang){
      if(lang == this.$i18n.locale) return;
      this.language = lang;
      this.$cookies.set('language', this.language, -1);
      this.$i18n.locale = this.language
    },
    toStringDate(date) {
      if(date) {
        let oneDay = 24*60*60*1000;
        let timeleft =(new Date(date)).getTime() -  Date.now();
        // 1 days max
        if(timeleft <= oneDay) {
          return 1 // return 1 days
        } else {
          let days = Math.round(timeleft / oneDay);
          return days // return days
        }
      } else {
        return '';
      }
    },
    toStringDate2(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR');
    },
    displayPlan(plan) {

      let p = null;
      switch (plan) {
        case 'free_trial':
          return 'Free trial'
          break;
        case 'premium_starter':
          let v = 'Premium'
          if(this.hasExpired) v += ' (' + this.$t('ph.expired') + ')'
          return v;
        default:
          return ''
      }
    },
    subscribe(){
      this.showSubscription = true;
    },
    goToStripe(address, tax_number, business_name){
      let self = this;

      self.stripe.status = null;
      self.stripe.session_id = null;
      self.showStripeResult = false;

      if(self.tax_number) {
        if(!self.tax_number.type) {
          return self.$bvToast.toast("You must select ID type for your tax number !", self.__toaster_opts('error', self.$t('w.error')));
        } else if(self.tax_number.type && (self.tax_number.value == null || self.tax_number.value == '' )){
          return self.$bvToast.toast("You must enter a valid tax number !", self.__toaster_opts('error', self.$t('w.error')));
        }
      }

      checkoutService.createSession('starter', address, tax_number, business_name).then(function(result) {
        self.showSubscription = false;
        window.location.href = result.body.session_url;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));

        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
      });
    },
    goToStripe2(){
      let self = this;
      checkoutService.createPortalSession().then(function(result) {
        window.location.href = result.body.session_url;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
      });
    },
    setWeeklyNews() {
      let self = this;
      userService.setWeeklyNews().then(function(result) {
        self.current_user.settings.newsletters.weekly = result.body.value;
        self.$cookies.remove('user_data_sbapp'); // force reload
      }, function(err) {
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', self.$t('w.error')))
        self.current_user.settings.newsletters.weekly = !self.current_user.settings.newsletters.weekly;
      });
    },
    setArticlesView(value) {
      this.$cookies.set('default_articles_view', (value)? 'grid' : 'list', -1);
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

#content {
  width: 90%;

};

.subMenuItem {
  color: $color-5;
  font-size: 1.5em;
  font-weight: 400;
  opacity: 0.8;
  cursor: pointer;
  margin-right: 1em;
  margin-bottom: 0.1em;

  hr {
    display: none;
    background: linear-gradient(to right, $color-5, $color-5-light);
    // opacity: .6 ;
    // border-radius: 3px;
    height: 1px;
    margin: 0;
    width: 100%;
  }
}

.subMenuItemActive {
  opacity: 1;
  hr {
    display: block;
  }
}

#tabs {
  .tabBody {
    min-height: 10em;
    width: 60%;
    background-color: #f4f3f2;
    padding: 0.5em 0.5em 0.5em 0.5em;
    border-radius: 0.5em;
  }
}

.labelInput {
  color: $color-5;
  min-width: 5em;
  opacity: 0.8;
}

.infoInput {
  font-weight: 600;
  color: $color-5;
}

@media screen and (max-width: 1079px) {
  #content {
    width: 100%;
  };

  #tabs {
    .tabBody {
      width: 100%;
    }
  }
}

</style>
