<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-06T13:44:40+02:00
-->

<template>
  <div>

    <!-- {{ taxNumberTypes }} -->

    <h4 class="largeTitle">{{ $t('w.address') }}</h4>

    <div class="d-flex flex-column mb-3">
      <b-form-select class="mb-1" v-model="address.country" :options="countries"></b-form-select>
      <b-input type="text" class="mb-1" v-model="address.line1" :placeholder="$t('inputs.address_1')"/>
      <b-input type="text" class="mb-1" v-model="address.line2" :placeholder="$t('inputs.address_2')"/>
      <b-form-select v-if="address.country == 'ca'" class="mb-1" v-model="address.state" :options="provinces"></b-form-select>
      <div class="d-flex mb-1">
        <b-input type="text" class="mr-1" v-model="address.postal_code" :placeholder="$t('inputs.postal_code')"/>
        <b-input type="text" v-model="address.city" :placeholder="$t('inputs.city')"/>
      </div>
      <b-form-select v-if="address.country == 'us'" class="myInput" v-model="address.state" :options="states"></b-form-select>
    </div>

    <b-form-checkbox v-model="isBusinessPurchase">{{ $t('account.company_name') }}</b-form-checkbox>

    <div v-if="isBusinessPurchase" class="d-flex flex-column my-2">

      <div class="d-flex align-items-center mb-2 mt-2">
        <span class="mr-1">{{ $t('inputs.vat_number') }}</span>
        <img v-b-tooltip.hover.bottom="{ variant: 'info' }" :title="$t('account.company_name_detail')" src="@/assets/img/png/ico_info.png" class="m-0 p-0" style="width:0.8em;height:0.8em;opacity:0.5;"/>
      </div>

      <b-input class="mb-1" type="text" v-model="business_name" :placeholder="$t('inputs.company_name')"/>
      <div class="d-flex align-items-start">
        <b-form-select class="myInput mr-1" v-model="tax_number.type" :options="taxNumberTypes"></b-form-select>
        <b-input type="text" v-model="tax_number.value"/>
      </div>

    </div>


    <hr>

    <b-button class="largeColoredButton" @click="subscribe()">{{ $t('btn.subscribe') }}</b-button>


  </div>
</template>

<script>

////////////////// Import //////////////////

import taxNumberTypes from '@/files/tax_number_types.json'

import iso_3166_1 from '@/files/iso-3166-1.json'
import canada_provinces from '@/files/canada_provs.json'
import usa_states from '@/files/usa_states.json'

////////////////// Functions //////////////////

export default {
  name: 'subscription',
  props: ['current_address'],
  data () {
    return {
      isBusinessPurchase: false,
      tax_number: {
        type: null,
        value: null
      },
      business_name: null,
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null,
        state: null
      }
    }
  },
  components: {

  },
  computed: {
    taxNumberTypes: function () {
      let list = taxNumberTypes.map(a => {
        return {
          value: { value: a.type, country: a.country },
          text: a.name +' - ' + this.$t('countries.' + a.country)
        }
      })
      list.sort((a,b) => {return (a.text.localeCompare(b.text) < 0)? -1 : 1});
      list.unshift({ value: null, text: this.$t('ph.tax_id') });
      return list;
    },
    countries() {
      let countries = iso_3166_1.map(a => {
        return {
          value: a.alpha_2.toLowerCase(),
          text: this.$t('countries.'+a.alpha_3.toLowerCase())
        }
      })
      countries.sort((a,b) => {return (a.text.localeCompare(b.text) < 0)? -1 : 1});
      countries.unshift({ value: null, text: this.$t('w.country') });
      return countries;
    },
    provinces() {
      let provinces = canada_provinces.map(a => {
        return {
          value: a.code.toLowerCase(),
          text: a.name
        }
      })
      provinces.sort((a,b) => {return (a.text.localeCompare(b.text) < 0)? -1 : 1});
      provinces.unshift({ value: null, text: 'Province'});
      return provinces;
    },
    states() {
      let states = usa_states.map(a => {
        return {
          value: a.code.toLowerCase(),
          text: a.name
        }
      })
      states.sort((a,b) => {return (a.text.localeCompare(b.text) < 0)? -1 : 1});
      states.unshift({ value: null, text: 'State'});
      return states;
    },
  },
  created: function() {
    this.address = {
      city: this.current_address.city,
      country: this.current_address.country,
      line1: this.current_address.line1,
      line2: this.current_address.line2,
      postal_code: this.current_address.postal_code,
      state: this.current_address.state
    };

  },
  methods: {
    subscribe() {
      if(this.isBusinessPurchase && !this.tax_number.type) return this.$bvToast.toast("You must chose a type for your tax Number", this.__toaster_opts('error', this.$t('w.error')));
      if(this.isBusinessPurchase && (!this.tax_number.value || this.tax_number.value == '')) return this.$bvToast.toast("You must enter your tax Number", this.__toaster_opts('error', this.$t('w.error')));
      if(this.isBusinessPurchase && (!this.business_name || this.business_name == '')) return this.$bvToast.toast("You must enter your business name", this.__toaster_opts('error', this.$t('w.error')));

      this.$emit('subscribe', { address: this.address, tax_number: (this.isBusinessPurchase)? { type: this.tax_number.type.value || this.tax_number.type, value: this.tax_number.value } : null, business_name: (this.business_name && this.business_name.length > 0)? this.business_name : null })
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';


</style>
